import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import posthog from 'posthog-js';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { env } from './env';
import { AuthProvider } from './hooks/useAuth/AuthProvider';

const ms = 1000;
const secs = 60;
const mins = 60;
const hours = 24;
const days = 7;

export const defaultStaleTime = ms * secs; // 1 minute
export const longStaleTime = ms * secs * mins; // 1 hour

const cacheTime = ms * secs * mins * hours * days; // 7 days

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
      staleTime: defaultStaleTime,
      // refetchOnMount: 'always',
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

if (env.VITE_APP_ENV === 'staging') {
  // TODO: separate keys for dev, staging & prod: https://posthog.com/tutorials/multiple-environments
  const posthogKey = 'phc_xV01B66fH5NfgfItqec7OGqbE7xEhkMtnjkuMgikVJa'; // ! TEMP >> VITE_POSTHOG_KEY
  posthog.init(posthogKey, {
    api_host: 'https://eu.i.posthog.com',
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '*',
    },
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister, buster: env.VITE_APP_VERSION, maxAge: cacheTime }}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  </React.StrictMode>,
);
