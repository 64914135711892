import { z } from 'zod';

export const filterGroupObjectTypeSchema = z.enum(['locations', 'courses']);

export const filterGroupSchema = z.object({
  defaultPlayerCount: z.string().nullish(),
  icon: z.string().nullable(),
  id: z.number().int().positive(),
  name: z.string(),
  objectType: filterGroupObjectTypeSchema,
  playerCountOptions: z.array(z.object({ playercount: z.string() })).nullish(),
});

export type FilterGroup = z.infer<typeof filterGroupSchema>;
