import z from 'zod';

const envVariables = z.object({
  VITE_APP_ENV: z.enum(['development', 'staging', 'prod']),
  VITE_APP_VERSION: z.string(),
  VITE_NAME: z.string(),
  VITE_API_URL: z.string(),
  VITE_SSO_URL: z.string(),
  VITE_SENTRY_DSN: z.string().optional(),
  VITE_POSTHOG_KEY: z.string().optional(),
  VITE_POSTHOG_HOST: z.string().optional(),
});

export const env = envVariables.parse(import.meta.env);
